import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Every three years, those with scientific interests in the geology common to all
parts of Gondwana  meet to discuss developments. In November 2017 it was the
turn of Bangkok and the Thai  Department of Mineral Resources to host the
meeting of 300 participants from 20 countries at a  convenient downtown
location. Talks were spread over four days and addressed many aspects  from
geodynamics to palaeontology but with an emphasis on the terranes that were
early-leavers  of Gondwana, migrating north to form what is now SE Asia while
the core of Gondwana remained intact. `}</p>
    <p>{`It was a time to meet up with many old colleagues. The picture shows Colin
Reeves with former  ITC student Simplicio Caluyong (Philippines), now working
with the Coordiation Committee for  Geoscience Programmes in East and Southeast
Asia (CCOP). In the background is the pre-final  edition of the new geological
map of Gondwana that attracted a great deal of attention. Thanks to  Simplicio
for sending the picture. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      